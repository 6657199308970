import './App.less';
import { HashRouter as Router } from 'react-router-dom';
import RouterView from './routes';


function App() {
  return (
    <div className="App">
      <Router>
        <RouterView />
      </Router>
    </div>
  );
}

export default App;
