import React, { useEffect, useMemo, useState } from 'react';
import { apiTestPost } from '@/utils/api.js';

function Home(props) {
  
  useEffect(() => {
    // console.log('Home');
    // apiTestPost({text: '123123123'}).then(res => {
    //   setMessage(res);
    // });
  }, [])

  const [message, setMessage] = useState("Welcome");

  return (
    <div>{  }</div>
  )
}

export default Home;
