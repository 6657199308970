import React from "react";
import { useRoutes } from "react-router-dom";
import Template from "@/pages/template";
import Home from "@/pages/Home";
import Hot from "@/pages/Hot";

const Routes = [
    {
        path: "/Home",
        title: "Home",
        element: <Home />
    },
    {
        path: "/hot",
        title: "Hot",
        element: <Hot />
    },
    {
        path: "/template",
        title: "template",
        element: <Template />
    },
    {
        path: "*",
        title: "template",
        element: <Home />

    }
]
export default function RouterView() {
    return useRoutes(Routes)
};