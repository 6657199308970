import axios from "axios";

function setRequestHeaders(opts, instance) {

	instance.defaults.headers["X-Source"] = "JinxCui";
	if (opts.headers) {
		instance.defaults.headers = {
			...instance.defaults.headers,
			...opts.headers
		};
		delete opts.headers;
	}
}

function Request() {
    const baseURL = "https://domain.cuijinxin.top"
    // const baseURL = "http://127.0.0.1:10086"
	const instance = axios.create();
	return function (conf) {
		const { url, opts = {}, headerLess = false } = conf || {};
		const method = conf.method;
		// 不加任何请求头
		if (!headerLess) {
			setRequestHeaders(opts, instance);
		}
		const params = method === "get" ? { params: opts } : { ...opts };

		return instance[method](baseURL + url, params)
			.then((res) => {
				if (res?.status === 200) {
					return res.data.data;
				} else {
					return Promise.reject(res);
				}
			})
			.catch(err => {
				if (err.response?.data) {
					return Promise.reject(err.response.data);
				} else {
					return Promise.reject(err);
				}
			});
	};
}

export default Request;
