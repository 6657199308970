import request from "./request";

const Request = request();


export function apiTest(opts) {
	return Request({
		method: "get",
		url: "/",
		opts
	});
}
export function apiTestPost(opts) {
	return Request({
		method: "post",
		url: "/tts/ttsServer",
		opts
	});
}

export function apiSearchBook(opts) {
	return Request({
		method: "get",
		url: "/book/search",
		opts
	});
}

/** 热门搜索 */
export function apiHotSearch(opts) {
	return Request({
		method: "get",
		url: "/hot/allList",
		opts
	});
}
/** 单个渠道热搜更新 */ 
export function apiHotSearchSingle(key) {
	return Request({
		method: "get",
		url: `/hot/${key}`
	});
}
