import React, { useEffect, useState } from 'react';
import { apiHotSearch, apiHotSearchSingle } from '@/utils/api.js';
import "./index.less";
import { Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

function Hot(props) {
  
  useEffect(() => {
    initHotData();
  }, [])
  const initHotData = async () => {
    const hotData = await apiHotSearch();
    console.log(hotData);
    setHotData(hotData);
    setHotDataKeys(Object.keys(hotData));
  }
  const [hotData, setHotData] = useState({});
  const [hotDataKeys, setHotDataKeys] = useState([]);
  const [refreshData, setRefreshData] = useState({});
  const [isRefresh, setIsRefresh] = useState({});

  useEffect(() => {
    let refreshData = {};
    hotDataKeys.forEach(key => {
      refreshData[key] = {
        date: getNowTime()
      }
    })
    setRefreshData(refreshData);
  }, [hotDataKeys])

  /** Methods Function */ 
  const onJump = (data) => {
    window.open(data.link, "_blank")
  }
  const getNowTime = () => {
    const hours = new Date().getHours();
    const min = new Date().getMinutes();
    // 获取当前时间
    return `更新于 ${hours > 9 ? hours: `0${hours}`}:${min > 9 ? min: `0${min}`}`;
  }
  // 点击刷新
  const onClickRefresh = (key) => {
    setIsRefresh({[key]: true});
    setTimeout(() => {
      setIsRefresh({[key]: false});
    }, 1000)
    apiHotSearchSingle(key).then(res => {
      hotData[key] = res;
      setHotData(hotData);
      refreshData[key].date = getNowTime();
      setRefreshData(refreshData);
      const dom = document.getElementById(`element_${key}`);
      if(dom) dom.scrollTop = 0;
    });
  }
  /**/ 
  return (
    <React.Fragment>
      <div className="hot-search-header"></div>
      <div className="hot-search-container">
        {
          hotDataKeys && hotDataKeys.map(key => {
            return (
              <div key={key} className="container-items">
                <div className="container-title">
                  <img
                    className="title-icon"
                    src={require(`@/assets/images/${key}.png`)}
                    alt={key}
                  />
                </div>
                <div className="container-body" id={`element_${key}`}>
                  {
                    hotData[key] && hotData[key].map((item, index) => {
                      return (
                        <div key={`${item.word}_${index}`} className="hot-item" onClick={ () => onJump(item)}>
                          <div className="hot-item-icon">{index + 1}</div>
                          <Tooltip title={item.title}>
                            <div className="hot-item-text">{item.title}</div>
                          </Tooltip>
                        </div>
                      )
                    })
                  }
                </div>
                <div className="container-footer">
                  <div className="refresh-date">{refreshData[key]?.date}</div>
                  <div className={isRefresh[key] ? "spin" : ""} onClick={() => onClickRefresh(key)}>
                    <ReloadOutlined />
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </React.Fragment>
  )
}

export default Hot;
