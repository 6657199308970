import React, { Component, } from 'react';

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }
  render() {
    return (
        <React.Fragment>测试页面</React.Fragment>
    );
  }
}
export default Template;
